import React from 'react'

import { IdentificationDocumentsReceived } from '../components/modals'

class IdentificationDocumentsReceivedPage extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: '#f5f5f5' }}>
        <IdentificationDocumentsReceived />
      </div>
    )
  }
}

export default IdentificationDocumentsReceivedPage
